import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom"
import useAuth from "../../Hook/Auth"
import { API_CONECTOR_CLOUD } from "../../Constantes";
import { useEffect } from "react";

const ProtectedPageMinimal = () => {
    const { user,loading,setSSE, setSseConectado} = useAuth()
    const location = useLocation();

    useEffect(() => {
        if (user && user?._id) {
            const _sse = new EventSource(API_CONECTOR_CLOUD + 'events/' + user._id)
            _sse.onopen = () => {
                setSseConectado(true)
            }
            _sse.onerror = () => {
                setSseConectado(false)
            }
            setSSE(_sse)
        }
    }, [user])

    if (!user && !loading) {
        return <Navigate to="/login" replace state={{ from: location }} />;
    }

    return <Outlet/>
}

export default ProtectedPageMinimal