import { Avatar, Button, Card, DatePicker, Form, Input, Modal, notification, Select, Space, Typography } from "antd"
import { useForm } from "rc-field-form"
import { useEffect, useState } from "react"
import API from "../../API"
import { LockOutlined, UserOutlined } from "@ant-design/icons"
import useAuth from "../../Hook/Auth"

const Registrarse = ({abrir_registro,setAbrirRegistro})=>{
    const {login} = useAuth()
    const [loading,setLoading] = useState(false)



    const guardar = async (payload)=>{
        try{
            const {data} = await API.post('usuario/registro',payload)
            login(data?.token)
        }catch(error){
            notification.open({
                placement:'top',
                type:'error',
                message:error?.response?.data?.message || error.toString()
            }) 
        }
    }

    useEffect(()=>{
    },[])

    const requerido = {required:true,message:"Completa este campo"} 


    return <Modal open={abrir_registro} onCancel={()=>setAbrirRegistro(false)} closable={!loading} footer={[]}>
        <Typography.Title level={3} style={{font:'normal normal medium 59px/89px Poppins',color:'#6901A0',textAlign:'center'}}>Crear Cuenta</Typography.Title>
        <div className="line" style={{width:'20%'}}></div>
        <Form layout="vertical" onFinish={guardar}>
            <Form.Item name="username" rules={[requerido]}>
                <Input prefix={<UserOutlined/>} placeholder="Nombre de usuario"/>
            </Form.Item>
            <Form.Item name="clave" rules={[requerido]}>
                <Input.Password prefix={<LockOutlined/>} placeholder="Contraseña" />
            </Form.Item>
            <Form.Item name="clave2" rules={[requerido,({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('clave') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Las contraseñas no coinciden!'));
            },
          })]} dependencies={['clave']}>
                <Input.Password prefix={<LockOutlined/>} placeholder="Confirmar contraseña"/>
            </Form.Item>
            <Button type="primary" htmlType="submit">Registrarme</Button>
        </Form>
    </Modal>

    
    
}

export default Registrarse