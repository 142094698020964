import "./estilo.css"
import useAuth from "../../Hook/Auth"
import { Navigate, Outlet, Link, useLocation, useNavigate } from "react-router-dom";
import { Flex, Layout, Menu, theme, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { BASE_URL } from "../../API";
import { API_CONECTOR_CLOUD, ROL_ADMIN } from "../../Constantes";
import FotoPerfil from "../FotoPerfil";
import Salir from "../Salir";
import ValidarRol from "../ValidarRol";
const { Header, Content, Footer, Sider } = Layout;


const ProtectedPage = () => {
    const navigate = useNavigate()
    const [items, setItems] = useState([])

    const { loading, user, logout, setSSE, setSseConectado, sse, setUser } = useAuth()
    const location = useLocation();
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const pagina_actual = location.pathname

    useEffect(() => {
        if (user && user?._id) {
            const _sse = new EventSource(API_CONECTOR_CLOUD + 'events/' + user._id)
            _sse.onopen = () => {
                setSseConectado(true)
            }
            _sse.onerror = () => {
                setSseConectado(false)
            }
            setSSE(_sse)

            if (user.rol == ROL_ADMIN) {
                setItems([
                    {
                        key: '1',
                        label: 'LMS',
                        children: [
                            {
                                key: 11,
                                label: <Link to='/admin/lms/categorias'>Categorias</Link>
                            },
                            {
                                key: 12,
                                label: <Link to='/admin/lms/cursos'>Cursos</Link>
                            }
                        ]
                    },
                    {
                        key: 2,
                        label: 'Bloque',
                        children: [
                            {
                                key: 21,
                                label: <Link to='/admin/categorias'>Categorias</Link>
                            },
                            {
                                key: 22,
                                label: <Link to='/admin/bloques'>Bloques</Link>
                            }
                        ]
                    }
                ])
            } else {
                setItems([])
            }
        }
    }, [user])



    if (!user && !loading) {
        return <Navigate to="/login" replace state={{ from: location }} />;
    }

    const c1 = ["item-principal", ['/', '/proyectos'].includes(pagina_actual) ? "active" : "", "proyecto"]
    const c2 = ["item-principal", ['/descubre'].includes(pagina_actual) ? "active" : "", "descubre"]
    const c3 = ["item-principal", ['/cursos'].includes(pagina_actual) ? "active" : "", "actividad"]

    return <div id="contenedor-principal">
        <Flex gap={'large'}>
            <Flex flex={1} vertical >

                <Flex gap="middle" justify="flex-start" align="center" style={{ marginBottom: 60 }}>
                    <FotoPerfil />
                    <Typography.Title onClick={() => setUser(prev => ({ ...prev, forzar_actualizar: true }))} level={4} style={{ color: 'whitesmoke', margin: 0 }}>{user?.username}</Typography.Title>
                </Flex>
                <Link className={c1.join(" ")} to="/proyectos">
                    <h2>Proyectos</h2>
                    <img />
                </Link>
                <Link className={c2.join(" ")} to="/descubre">
                    <h2>Descubre</h2>
                    <img />
                </Link>
                <Link className={c3.join(" ")} to="/cursos">
                    <h2>Cursos</h2>
                    <img />
                </Link>

                <ValidarRol roles={['Admin']}>
                    <Menu
                        theme="dark"
                        mode="vertical"
                        items={items}
                    />
                </ValidarRol>



                <Salir />

                <center>
                    <img style={{ maxWidth: '100%', width: '25%', marginBottom: 8 }} src={`${process.env.PUBLIC_URL}/imagenes/logow.png`} />
                    <a target="blank" href="https://ufotech.co/" style={{ color: '#10C491' }}>
                        <img src={`${BASE_URL}images/poweredbyufow.png`} style={{ maxWidth: 200, width: '80%', bottom: 16, left: 16 }} />
                    </a>
                </center>

            </Flex>
            <div id="contenedor-contenido">
                <Outlet />
            </div>
        </Flex>
    </div>

}
export default ProtectedPage