import axios from "axios";
import { API_CONECTOR } from "../Constantes";

export const BASE_URL= process.env.REACT_APP_BASE_URL 

const API=axios.create({
    baseURL:BASE_URL+'api/',
   // withCredentials: true
})

export const Conector=axios.create({
    baseURL:API_CONECTOR
})

API.interceptors.request.use((config)=>{
    const token = localStorage.getItem('token')
  
    if(token && token!='' && token!='null'){
        config.headers['authorization']='Bearer '+token
    }

    return config
})

export default API