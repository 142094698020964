import { useEffect } from "react"
import useAuth from "../../Hook/Auth"

const Logout=()=>{
    const {logout} = useAuth()

    useEffect(()=>{
        logout()
    },[])
    
    return <></>
}

export default Logout