import {Howl} from 'howler';

export const BASE_URL= process.env.REACT_APP_BASE_URL 
export const FORMATO_FECHA = 'DD/MM/YYYY'
export const FORMATO_FECHA_HORA = 'DD/MM/YYYY hh:mm a'
export const API_CONECTOR='http://localhost:9204/'
export const API_CONECTOR_CLOUD=1==1 ? 'https://conector.botflow.ufotech.co/' : 'http://localhost:4000/'
//

export const requerido={required:true,message:'Requerido'}
export const PLACAS = [{label:'ESP32',value:'esp32:esp32:nodemcu-32s'}]

export const SONIDO_BORRAR = new Howl({
    src: [`${BASE_URL}Editor/media/delete.ogg`,`${BASE_URL}Editor/media/delete.wav`]
 });

 export const S3='https://lms-botflow.nyc3.digitaloceanspaces.com/'


 export const SONIDO_INCORRECTO = new Howl({
    src: [`${BASE_URL}Sonidos/incorrecto.mp3`]
 });

 export const SONIDO_CORRECTO = new Howl({
    src: [`${BASE_URL}Sonidos/correcto.mp3`]
 });

 export const SONIDO_HOVER = new Howl({
    src: [`${BASE_URL}Sonidos/bubble.mp3`]
 });

 export const ROL_ADMIN='Admin'

export const LECCION_TEXTO=0
export const LECCION_CUESTIONARIO=1

export const TIEMPO_ESPERA_VIDEO=3

