import { createContext, useContext, useEffect, useMemo, useState } from "react";
import API from "../../API";

export const CursosContext = createContext();

const CursosProvider=({children})=>{
    const [cargando,setCargando] = useState(false)
    const [docs,setDocs] = useState([])
    const [params,setParams] = useState({})

    const cargar= async (extra={})=>{
        try{
            setCargando(true)
            const {data} = await API('curso',{params:{...params,...extra}})
            setParams({...extra,total:data.total,last:data.last})
            setDocs(prev=>([...prev,...data?.docs]))
        }catch(error){

        }finally{
            setCargando(false)
        }
    }

    const buscar=(q)=>{
        setDocs([])
        cargar({q,total:0,last:null})
    }

    return <CursosContext.Provider value={{buscar,cargar,docs,params,cargando}}>{children}</CursosContext.Provider>
}

export default CursosProvider