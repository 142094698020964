import { Result } from "antd"

const { default: useAuth } = require("../../Hook/Auth")

const ValidarRol=({roles=[],children,show_feedback=false})=>{
    const {user} = useAuth()
    const valid = (roles || ['Invitado']).includes(user.rol)

    if(!valid && show_feedback){
        return <Result status={403} title='Acceso restringido'/>
    }
    if(!valid && !show_feedback){
        return <></>
    }

    return children
}

export default ValidarRol