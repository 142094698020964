import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import API from "../../API";
import { Spin, notification } from "antd";


export const AuthContext = createContext();

const AuthProvider=({children})=>{
    const location = useLocation();
    const [token, setToken] = useState(localStorage.getItem("token"));
    const [user, setUser] = useState(null);
    const [valid, setValid] = useState(null);
    const [loading, setLoading] = useState(true);
    const [sse,setSSE] = useState(null)
    const [sse_conectado,setSseConectado] = useState(false)

    const login = async (data) => {
        localStorage.setItem('token',data)
        setToken(data);
        setUser(null)
        setLoading(true)
    };

    const logout = () => {

        setUser(null)
        setToken(null);
        localStorage.removeItem('token')  
       
    };

    const validateSession=async ()=>{
      try{
        if(location.pathname=='/login' && !token){
          setLoading(false)
          return
        }
        console.log("Validar sesion")
        setLoading(true)
        const {data} = await API('usuario/session',{credentials: "include"})
        const {token:_token,...datos_usuario} = data
        if(token){
          localStorage.setItem("token",_token)
        }
        setUser(datos_usuario)
        setLoading(false)
      }catch(error){
        console.log('Error validando sesion ',error)
        logout()
        setUser(null)
        setLoading(false)
        notification.warning({message:error?.data?.message || 'No hemos podido validar tú cuenta, por favor vuelve a iniciar sesión'})
      }
    }

    useEffect(()=>{
    
     if(token && token!=null && token!='' && token!='null'){
        validateSession()
       }else{
        console.log("Ir al login")
        logout()
        setLoading(false)
      }
    },[token])

    // useEffect(()=>{
    //   if(valid){
    //     const origin = sessionStorage.getItem('pathname') || '/';
    //     navigate(origin);
    //   }else if(valid===false){
    //     navigate("/", { replace: true });
    //   }
    // },[valid])

    if(!['/login'].includes(location.pathname)){
      sessionStorage.setItem('pathname',location.pathname)
    }    
    

    const value = {
          token,
          user,
          setUser,
          loading,
          login,
          logout,
          sse,
          setSSE,
          sse_conectado,
          setSseConectado
    }

    if(loading){
      return '...'
    }
    

    return <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>;
}

export default AuthProvider