import { Upload, message } from "antd"
import useAuth from "../../Hook/Auth"
import { useState, useMemo } from "react"
import { BASE_URL } from "../../API"
import { minidenticon } from 'minidenticons'

const FotoPerfil = ({ size = 56 }) => {

    const { user, setUser } = useAuth()
    const [t, setT] = useState(Date.now())
    const [cargando, setCargando] = useState(false)

    const { username } = user

    const svgURI = useMemo(
        () => 'data:image/svg+xml;utf8,' + window.encodeURIComponent(minidenticon(username, 60, 50)),
        [username]
    )

    const props = useMemo(() => ({
        name: 'archivo',
        action: `${BASE_URL}api/usuario/foto-perfil`,
        headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                setCargando(true)
            }
            if (info.file.status === 'done') {
                setCargando(false)
                message.success(`${info.file.name} archivo subido`);
                setUser(prev => ({ ...prev, foto: true }))
                setT(Date.now())
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} error al subir.`);
                setCargando(false)
            }
        }
    }), [user])


    return <div className="foto-perfil" style={{ width: size, height: size }}>
        <Upload
            name="avatar"
            showUploadList={false}
            maxCount={1}
            {...props}>
            {user?.foto ? <img width={size} height={size} style={{ backgroundColor: '#3ACEAD' }} src={`${BASE_URL}${user._id}?t=${t}`} /> : <img src={svgURI} width={size} height={size} />}
        </Upload>

    </div>
}

export default FotoPerfil