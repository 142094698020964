import { Affix, Avatar, Button, Flex, Layout, Menu, Modal, Space, Spin, theme, Typography } from 'antd';
import useAuth from '../../Hook/Auth';
import { useState } from 'react';
import API from '../../API';

const Salir = () => {
    const { logout, usuario } = useAuth()
    const [open, setOpen] = useState(false)
    const [cargando, setCargando] = useState(false)

    const salir = async () => {
        try {
            setCargando(true)
            await API.post('usuario/salir', { credentials: "include" })
            logout()
            if (usuario?.wpid > 0) {
                window.location = 'https://lms.botflow.ufotech.co/prueba'
            }
        } catch (err) {

        } finally {
            setCargando(false)
        }
    }

    return <>
        <Modal open={open} onCancel={() => setOpen(false)} closable={!cargando} footer={[]}>
            <Spin spinning={cargando}>
                <h2 className='titulo'>¡Hola!</h2>
                <p style={{textAlign:'left'}}>¡Es hora de decir adiós por ahora! 😊 Gracias por visitar nuestro mundo virtual. ¡Esperamos verte de nuevo pronto para más diversión y aprendizaje!
                <br></br>
                <br></br>
                👋 ¡Chau chau! 🌈</p>
                <Flex justify='center'>
                    <Button danger size='large' onClick={salir}>Cerrar Sesión</Button>
                </Flex>
            </Spin>
        </Modal>
        <Button type="text" style={{ width: '100%', border: '1px solid white', borderRadius: 9, height: 58, color: 'white', marginTop: 42, marginBottom: 42 }} onClick={() => setOpen(true)}>Cerrar Sesión</Button>
    </>
}

export default Salir