import { Button, Card, Col, Form, Input, notification, Row, Space, Spin, Typography } from "antd"
import { useEffect, useState } from "react"
import { Navigate, NavLink } from "react-router-dom"
import API, { BASE_URL } from "../../API"
import useAuth from "../../Hook/Auth"
import { UserOutlined, LockOutlined, LoadingOutlined } from "@ant-design/icons"
import './estilo.css'
import Registrarse from "../Registro"

const Login = () => {
    const { login, logout, user, loading } = useAuth()
    const [cargando, setCargando] = useState(false)
    const [abrir_registro, setAbrirRegistro] = useState(false)

    const acceder = async (payload) => {

        try {

            setCargando(true)
            const { data } = await API.post('usuario/login', payload)
            login(data?.token)

        } catch (error) {
            notification.open({
                type: 'error',
                message: error?.response?.data?.message || error?.response?.data?.errors?.map(e => e.message).join(", ") || error.toString()
            })
        } finally {
            setCargando(false)
        }
    }



    if (user && !loading) {
        console.log('Redirigir')
        return <Navigate to="/" replace />;
    }

    return <div className="page-login" >
        <Registrarse abrir_registro={abrir_registro} setAbrirRegistro={setAbrirRegistro} />
        <div className="content-page" style={{ display: 'block' }}>
            <img src={`${BASE_URL}images/logo.png`} style={{ width: '20%', minWidth: 200, maxWidth: 400, marginBottom: 64 }} />
            <Row gutter={[16, 16]} align="middle">

                <Col xs={24} md={12}>

                    <Card style={{ maxWidth: 400, width: '100%', margin: '0 auto', textAlign: 'center', borderRadius: 22, background: '#44088D88', borderColor: '#FFEA31' }}>

                        <Typography.Title level={3} style={{ font: 'normal normal medium 59px/89px Poppins', color: 'whitesmoke', textAlign: 'center' }}>Iniciar Sesión</Typography.Title>
                        <br></br>
                        {cargando ? <Spin indicator={<LoadingOutlined style={{fontSize:32}}/>}><Typography.Title level={4} style={{color:'whitesmoke'}}>Validando espere por favor...</Typography.Title></Spin>:   <Form layout="vertical" onFinish={acceder} disabled={cargando}>
                            <Form.Item label="" name="username" rules={[{ required: true, message: "Completa este campo" }]}>
                                <Input prefix={<UserOutlined />} placeholder="Nombre de usuario" />
                            </Form.Item>
                            <Form.Item label="" name="password" rules={[{ required: true, message: "Completa este campo" }]}>
                                <Input.Password prefix={<LockOutlined />} placeholder="Contraseña" />
                            </Form.Item>
                            <br></br>
                            <Typography.Text style={{ color: 'whitesmoke' }}>¿No tienes una cuenta? <Button type="link" onClick={() => setAbrirRegistro(true)} style={{ color: '#FFEA31' }}>Únete a BotFlow</Button> </Typography.Text>
                            <br></br>
                            <br></br>
                            <Button htmlType="submit" loading={cargando} type="primary">Ingresar</Button>
                        </Form>}
                      
                    </Card>

                </Col>

                <Col xs={24} md={12}>
                    <div style={{ textAlign: 'center' }}>
                        <img src={`${BASE_URL}images/frase.png`} className="animate__animated animate__tada" style={{ maxWidth: 400 }} />
                        <img src={`${BASE_URL}images/calvin.png`} style={{ maxWidth: 400 }} />
                        {/* <button className="boton" onClick={()=>setAbrirRegistro(true)}>CREAR CUENTA</button> */}
                    </div>
                </Col>
            </Row>
            <div style={{ display: 'flex', justifyContent: 'end', marginTop: 64 }}>
                <img src={`${BASE_URL}images/poweredbyufow.png`} style={{ width: '20%', minWidth: 200, maxWidth: 400 }} />
            </div>
        </div>
    </div>
}

export default Login