import './App.css';
import { RouterProvider, createBrowserRouter, Outlet } from 'react-router-dom'
import Login from './Pages/Login';
import ProtectedPage from "./Components/ProtectedPage"
import Logout from './Pages/Logout';
import { ScrollRestoration } from "react-router-dom"
import AuthProvider from './Context/Auth';
import DescubreProvider from './Context/Descubre';
import ProtectedPageMinimal from './Components/ProtectedPageMinimal';
import CursosProvider from './Context/Cursos';
import { AnimatePresence } from "framer-motion";

function NoLogin() {
  return <> <div className='fondo'></div><AuthProvider><Outlet /></AuthProvider></>
}

function ConLogin() {
  return <>
    <div className='fondo'></div>
    <AuthProvider>
      <CursosProvider>
        <DescubreProvider>
          <ProtectedPage>
            <Outlet />
            <ScrollRestoration />
          </ProtectedPage>
        </DescubreProvider>
      </CursosProvider>
    </AuthProvider>
  </>
}

function Programar() {
  return <AuthProvider>
    <ProtectedPageMinimal>
      <Outlet />
    </ProtectedPageMinimal>
  </AuthProvider>
}

function App() {

  const router = createBrowserRouter([
    {
      path: '/login',
      element: <NoLogin />,
      children: [{ path: '/login', element: <Login /> }]
    },
    {
      path: '/logout',
      element: <NoLogin />,
      children: [{ path: '/logout', element: <Logout /> }]
    },
    {

      path: '/proyecto/:id/programar',
      element: <Programar />,
      children: [{
        path: '/proyecto/:id/programar',
        lazy:()=>import('./Pages/Proyectos/Programar')
      }]
    },
    {
      path: '/admin',
      element: <ConLogin />,
      children: [
        {
          path: 'lms/cursos',
          lazy:()=>import("./Pages/Admin/LMS/Curso/Listado")
        },
        {
          path: 'lms/cursos/guardar/:id',
          lazy:()=>import('./Pages/Admin/LMS/Curso/Guardar')
        },
        {
          path: 'lms/categorias',
          lazy:()=>import('./Pages/Admin/LMS/Categoria/Listado')
        }
      ]
    },
    {
      path: '/',
      element: <ConLogin />,
      children: [
        {
          path: '/',
          lazy:()=>import('./Pages/Proyectos')
        },
        {
          path: '/proyectos',
          lazy:()=>import('./Pages/Proyectos')
        },
        {
          path: '/proyecto/:id/detalle',
          lazy:()=>import('./Pages/Proyectos/Detalle')
        },
        {
          path: '/descubre',
          lazy:()=>import('./Pages/Descubre')
        },
        {
          path: '/cursos',
          lazy:()=>import('./Pages/Cursos/Listado')
        },
        {
          path: '/curso/:id',
          lazy:()=>import('./Pages/Cursos/Detalle')
        },
        {
          path: '/asignamiento/:id',
         lazy:()=>import('./Pages/Asignamiento/Detalle')
        },
        {
          path: '/admin/categorias',
          lazy:()=>import('./Pages/Categoria/Listado')
        },
        {
          path: '/admin/bloques',
          lazy:()=>import('./Pages/Bloques/Listado')
        }
      ]
    },


  ])

  return (<AnimatePresence mode='wait'><RouterProvider router={router} /></AnimatePresence>);
}

export default App;
