import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from 'antd';
import dayjs from "dayjs"

import "react-nice-scroll/dist/styles.css";
import relativetime from "dayjs/plugin/relativeTime"
import es from "dayjs/locale/es"


const root = ReactDOM.createRoot(document.getElementById('root'));

dayjs.extend(relativetime)
dayjs.locale('es')
const data={
  borderRadius: 40,
  colorPrimary: '#777CFE',
  Button: {
    colorPrimary: '#777CFE',
  },
}
{/* <React.StrictMode> */}


root.render(
    <ConfigProvider  theme={{
          token: {
            colorPrimary: data.colorPrimary,
            borderRadius: data.borderRadius,
            colorText:'#44088D'
          },
          components: {
            Button: {
              colorPrimary: data.Button?.colorPrimary,
              algorithm: data.Button?.algorithm,
            },
          },
        }}>
          <App />
    </ConfigProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
